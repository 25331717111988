<template>
    <div class="w-100 alignment">
        <section class="pa-2 bg-white w-100">
            <router-link :to="{name:'live'}">Indietro</router-link>
        </section>
        <section v-if="majors" class="pb-6 alignment">
          <template v-if="turn.majors_only">
            <div v-for="(major, key) in this.majors"
                 :key="key"
                 class="my-2 align-center justify-space-between councilor pt-2 pb-2">
              <v-avatar :image="major.image" :alt="major.surname" />
              <p class="text-center"><b>{{ parseInt(current_votes.majors[major.id] || 0) + parseInt(datas.majors[major.id] || 0) }}</b></p>
              <div class="d-flex justify-center align-center">
                <v-btn @click.stop="subtract('majors', major.id)" size="small">-</v-btn>
                <v-text-field type="number" v-model="datas.majors[major.id]" @click.stop="" variant="outlined"></v-text-field>
                <v-btn @click.stop="add('majors', major.id)" size="small">+</v-btn>
              </div>
            </div>
          </template>
            <v-expansion-panels v-else>
                <v-expansion-panel elevation="0" v-for="(major, key) in this.majors" :key="key">
                    <v-expansion-panel-title>
                        <div class="w-100 d-flex align-center justify-space-between mr-3 alignment">
                            <v-avatar :image="major.image" :alt="major.surname" />
                            <p>{{ parseInt(current_votes.majors[major.id] || 0) + parseInt(datas.majors[major.id] || 0) }}</p>
                            <div class="d-flex justify-center align-center">
                                <v-btn @click.stop="subtract('majors', major.id)" size="small">-</v-btn>
                                <v-text-field type="number" v-model="datas.majors[major.id]" @click.stop="" variant="outlined"></v-text-field>
                                <v-btn @click.stop="add('majors', major.id)" size="small">+</v-btn>
                            </div>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-expansion-panels>
                            <v-expansion-panel elevation="0" v-for="(group, key) in this.major_groups[major.id]" :key="key">
                                <v-expansion-panel-title>
                                    <div class="w-100 d-flex align-center justify-space-between mr-3">
                                        <v-avatar :image="group.image" :alt="group.name"></v-avatar>
                                        <p>{{ parseInt(current_votes.groups[group.id] || 0) + parseInt(datas.groups[group.id] || 0)}}</p>
                                        <div class="d-flex justify-center align-center alignment">
                                            <v-btn @click.stop="subtract('groups', group.id)" size="small">-</v-btn>
                                            <v-text-field type="number" v-model="datas.groups[group.id]" @click.stop="" variant="outlined"></v-text-field>
                                            <v-btn @click.stop="add('groups', group.id)" size="small">+</v-btn>
                                        </div>
                                    </div>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="councilors-container">
                                    <div v-for="(councilor, key) in this.group_councilors[group.id]"
                                         :key="key"
                                         class="my-2 align-center justify-space-between councilor pt-2 pb-2">
                                        <p class="name">{{councilor.surname}}</p>
                                        <p>{{ parseInt(current_votes.councilors[councilor.id] || 0) + parseInt(datas.councilors[councilor.id] || 0) }}</p>
                                        <div class="d-flex justify-center align-center alignment">
                                            <v-btn @click.stop="subtract('councilors', councilor.id)" size="small">-</v-btn>
                                            <v-text-field type="number" v-model="datas.councilors[councilor.id]" @click.stop="" variant="outlined"></v-text-field>
                                            <v-btn @click.stop="add('councilors', councilor.id)" size="small">+</v-btn>
                                        </div>
                                    </div>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </section>
        <section style="position: fixed; bottom: 0; left: 0; z-index: 99" class="bg-red w-100 pa-3 d-flex justify-space-between elevation-5">
            <v-btn color="yellow" @click="saveAndClose" :disabled="saving">Salva e chiudi</v-btn>
            <v-btn color="blue" @click="save" :disabled="saving">Salva</v-btn>
        </section>
    </div>
</template>

<script>
import {useGroupsStore} from "@/stores/groups";
import {useCouncilorsStore} from "@/stores/councilors";

export default {
    name: 'AlignmentPage',
    setup(){
        const groupsStore = useGroupsStore();
        const councilorsStore = useCouncilorsStore();
        return {groupsStore, councilorsStore};
    },
    data() {
        return {
            majors: null,
            turn: null,
            voting_station: null,
            major_groups: {},
            group_councilors: {},
            datas: {
                majors: {},
                groups: {},
                councilors: {}
            },
            current_votes: {
                majors: {},
                groups: {},
                councilors: {}
            },
            saving: false
        }
    },
    beforeMount() {
        this.axios.get(`voting_stations/${this.$route.params.votingStationId}`)
            .then(res => res.data)
            .then(res => {
                this.voting_station = res.data;
                this.turn = this.voting_station.turn;
                this.majors = this.turn.majors;

                for (let major of this.majors) {
                    this.groupsStore.getOfMajor(major.id)
                        .then(groups => {
                            this.major_groups[major.id] = groups;

                            for (let group of groups) {
                                this.councilorsStore.getOfGroup(group.id)
                                    .then(councilors => this.group_councilors[group.id] = councilors);
                            }
                        });
                }
            })

        this.axios.get(`voting_stations/${this.$route.params.votingStationId}/overview`)
            .then(res => res.data)
            .then(res => this.current_votes = res.data);
    },
    methods: {
        subtract(where, id){
            if (!this.datas[where][id]) this.datas[where][id] = 0;
            return this.datas[where][id]--;
        },
        add(where, id){
            if (!this.datas[where][id]) this.datas[where][id] = 0;
            return this.datas[where][id]++;
        },
        save(){
            this.saving = true;
            return this.axios.post(`turns/${this.turn.id}/votes/bulk`, {
                "voting_station_id": this.$route.params.votingStationId,
                ...this.datas
            })
                .then(res => res.data)
                .then(res => {
                    this.current_votes = res.data;
                    this.datas.majors = {};
                    this.datas.groups = {};
                    this.datas.councilors = {};
                    this.saving = false;
                });
        },
        saveAndClose(){
            return this.save()
                .then(() => {
                    this.$router.push({ name: 'live', params: { votingStationId: this.$route.params.votingStationId}});
                });
        }
    }
}
</script>

<style>
    .alignment .v-expansion-panel-title {
       padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .councilor {
        display: grid;
        grid-template-columns: 100px 30px auto;
    }

    .councilor .name {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .councilor:nth-child(odd) {
        background: #f1f1f1;
    }

    .councilors-container > .v-expansion-panel-text__wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .alignment .v-input {
        width: 70px;
        position: relative;
    }

    .alignment .v-input:before {
        content: 'Variazione';
        position: absolute;
        top: 100%;
        text-transform: uppercase;
        color: #929292;
        font-size: 10px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    }

    .alignment .v-input__details{
        display: none !important;
    }
</style>
