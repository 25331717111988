import { defineStore } from 'pinia'
import {useUserStore} from "@/stores/user";
import axios from "axios";
import {watch} from "vue";

export const useElectionsStore = defineStore('elections', {
    state: () => ({
        elections: null,
        gettingElections: false
    }),
    getters: {

    },
    actions: {
        getOfUser(){
            return new Promise((resolve, reject) => {
                if (this.elections) return resolve(this.elections);

                if (this.gettingElections){
                    const unwatch = watch(() => this.gettingElections,
                        (newValue) => {
                            if (!newValue)
                                return resolve(this.elections);

                            unwatch();
                        }, {deep: true});
                    return;
                }

                this.gettingElections = true;
                useUserStore().getUser()
                    .then(user => {
                        axios.get('/users/' + user.id + '/elections')
                            .then(res => res.data)
                            .then(res => {
                                this.elections = res.data;
                                resolve(this.elections);
                                this.gettingElections = true;
                            })
                            .catch(e => reject(e));
                    });

            })
        },
        find(id){
            return new Promise((resolve, reject) => {
                if (this.elections && this.elections.length){
                    let election = this.elections.find(e => e.id === id);
                    console.log(this.elections, election);
                    return resolve(election);
                }

                this.getOfUser()
                    .then(() => resolve(this.find(id)))
                    .catch(e => reject(e));
            })
        }
    }
})
