<template>
    <v-main class="my-auto">
        <v-card class="mx-auto px-2 py-2" max-width="344" elevation="3">
            <v-card-item>
                <v-card-title class="text-center mt-2">
                    <img src="../../assets/logo.svg" alt="Spogliolive" class="ma-auto">
                </v-card-title>
            </v-card-item>
            <v-card-item v-if="user">
                <div class="mt-2">
                    <v-text-field clearable
                                  label="Email"
                                  variant="outlined"
                                  v-model="user.email"
                                  :rules="[rules.required]"
                                  :disabled="true"
                    ></v-text-field>
                    <v-text-field clearable
                                  label="Nome"
                                  variant="outlined"
                                  v-model="user.name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field clearable
                                  label="Password"
                                  variant="outlined"
                                  type="password"
                                  v-model="password"
                                  :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field clearable
                                  label="Conferma password"
                                  variant="outlined"
                                  type="password"
                                  v-model="password_confirmation"
                                  :rules="[rules.required]"
                    ></v-text-field>
                </div>
            </v-card-item>
            <v-card-actions>
                <v-btn class="mx-auto bg-blue-darken-1 text-none font-weight-bold" @click="saveUser">
                    Salva
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-main>
</template>

<script>
import {useUserStore} from "@/stores/user";

export default {
    name: "InvitedPage",
    setup() {
        const userStore = useUserStore();

        return {userStore};
    },
    data() {
        return {
            user: null,
            password: null,
            password_confirmation: null,
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
        }
    },
    beforeMount() {
        this.userStore.getInvited(this.$route.params.token)
            .then(res => {
                console.log(res);
                this.user = res;
            })
    },
    methods: {
        saveUser() {
            this.axios.post(`/users/invited/${this.$route.params.token}`, {
                email: this.user.email,
                name: this.user.name,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }).then(() => {
                this.$router.push({ name: 'login' });
            })
        }
    }
}
</script>
