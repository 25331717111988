<template>
    <div>
        <v-card class="ma-4" elevation="0">
            <v-card-title>Assegna seggi</v-card-title>

            <v-card-item>
                <station-card v-for="(station, key) in this.stations"
                              :key="key"
                              :station="station"
                              :turn_id="$route.params.turnId"
                              :voting_station="voting_stations.find(vs => vs.station.id === station.id)"
                ></station-card>
            </v-card-item>
        </v-card>
    </div>
</template>

<script>
import {useElectionsStore} from "@/stores/elections";
import {useVotingStationsStore} from "@/stores/voting_stations";
import StationCard from "@/components/pages/StationCard.vue";

export default {
    name: "VotingStationsPage",
    components: {StationCard},
    setup() {
        const electionsStore = useElectionsStore();
        const votingStationsStore = useVotingStationsStore();

        return {electionsStore, votingStationsStore};
    },
    data() {
        return {
            stations: null,
            voting_stations: null
        }
    },
    beforeMount() {
        console.log("ola", this.$route.params.electionId);
        this.electionsStore.find(parseInt(this.$route.params.electionId))
            .then(election => {
                console.log(election);
                this.axios.get(`cities/${election.city.id}/stations`)
                    .then(res => res.data)
                    .then(res => this.stations = res.data);
            })

        this.votingStationsStore.getOfTurn(parseInt(this.$route.params.turnId))
            .then(res => this.voting_stations = res);
    }
}
</script>

<style>
.station:nth-child(odd){
    background: whitesmoke;
}
</style>
