<template>
  <div class="live">
    <section id="actions">
      <router-link :to="{ name: 'dashboard' }">Esci</router-link>
      <router-link :to="{ name: 'alignment' }">
        <v-btn color="blue">Allineamento</v-btn>
      </router-link>
    </section>
    <section id="exception-votes">
      <button @click="blank_modal = true">BIANCA</button>
      <button @click="null_modal = true">NULLA</button>
    </section>
    <section id="close-station" v-if="voting_station">
      <button @click="closeVotingStation">
        <template v-if="voting_station.closed_at">
          Apri sezione
        </template>
        <template v-else>
          Chiudi sezione
        </template>
      </button>
    </section>
    <section v-if="majors" id="majors" :class="{majors_only: turn.majors_only}">
      <img v-for="(major, key) in this.majors" :key="key"
           @click="selectMajor(major.id)"
           :class="{ selected: vote.major_id === major.id }"
           :src="major.image"
           :alt="major.surname + ' ' + major.name"
      >
    </section>
    <section v-if="vote.major_id !== null && !turn.majors_only && (vote.major_id in groups)" id="groups">
      <template v-if="vote.group_id === null">
        <div id="toggle-groups">
          <div @click="major_groups = true" :class="{ active: major_groups }">COALIZIONE</div>
          <div @click="major_groups = false" :class="{ active: !major_groups }">DISGIUNTO</div>
        </div>
        <div id="groups-container">
          <template v-if="major_groups">
            <div v-for="(group, key) in groups[vote.major_id].sort((a, b) => a.number - b.number)" :key="key"
                 @click="selectGroup(group.id)"
                 :class="{ selected: vote.group_id === group.id }"
                 class="group"
            >
              <img
                  :src="group.image"
                  :alt="group.name"
              >
              <span class="elevation-3">LISTA {{ group.number }}</span>
            </div>
          </template>
          <template v-else>
            <div v-for="(major, major_key) in majors.filter(m => m.id !== vote.major_id)" :key="major_key"
                 class="major-groups-container">
              <div class="activator"
                   @click="major_list === major.id ? major_list = null : major_list = major.id">
                <div>{{ major.surname }}</div>
                <i v-if="major_list !== major.id" class="icon-chevron-right"></i>
                <i v-else class="icon-chevron-down"></i>
              </div>
              <div class="groups-container" v-if="major_list === major.id">
                <div v-for="(group, group_key) in this.groups[major.id].sort((a, b) => a.number - b.number)"
                     :key="group_key"
                     @click="selectGroup(group.id)"
                     :class="{ selected: vote.group_id === group.id }"
                     class="group"
                >
                  <img
                      :src="group.image"
                      :alt="group.name"
                  >
                  <span class="elevation-3">LISTA {{ group.number }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div id="selected_group">
          <img :src="selected_group.image" :alt="selected_group.name">
          <div>
            <p class="group_name">{{ selected_group.name }}</p>
            <p v-if="major_groups">COALIZIONE</p>
            <p v-else>DISGIUNTO</p>
          </div>
        </div>
      </template>

    </section>
    <section v-if="vote.group_id" id="councilors">
      <div class="male">
        <p v-for="(councilor, key) in maleCouncilors"
           :key="key"
           :class="{selected: vote.councilors.includes(councilor.id)}"
           @click="addCouncilor(councilor)"
        >
          <b>{{ councilor.surname }}</b> {{ councilor.name }}
        </p>
      </div>
      <div class="female">
        <p v-for="(councilor, key) in femaleCouncilors"
           :key="key"
           :class="{selected: vote.councilors.includes(councilor.id)}"
           @click="addCouncilor(councilor)"
        >
          <b>{{ councilor.surname }}</b> {{ councilor.name }}
        </p>
      </div>
    </section>
    <section id="final-actions" v-if="turn && !turn.majors_only">
      <button @click="reset">RESET</button>
      <button @click="vote.major_id !== null ? asking = true : null">INVIA</button>
    </section>

    <v-dialog v-model="asking">
      <v-card>
        <v-card-title>Conferma l'invio del voto</v-card-title>
        <v-card-item>
          Confermi di voler inviare il seguente voto?
          <br/>
          <br/>
          <template v-if="selected_major">
            Sindaco: <b>{{ selected_major.surname }}</b><br/>
          </template>

          <template v-if="selected_group">
            Lista: <b>{{ selected_group.name }}</b><br/>
          </template>
          <br/>
          <template v-if="selected_councilors.length">
            Consiglieri:<br/>
            <template v-for="councilor in selected_councilors" :key="councilor.id">
              <span><b>{{ councilor.surname }}</b></span><br/>
            </template>
          </template>
        </v-card-item>
        <v-card-actions>
          <button class="ma-auto back" @click="asking = false">Indietro</button>
          <button class="ma-auto final" @click="send">Conferma</button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="blank_modal">
      <v-card>
        <v-card-title>Modifica schede bianche</v-card-title>
        <v-card-item>
          Il numero di schede bianche nel tuo seggio è {{ voting_station.blank_papers }}. Vuoi modificarlo?
          <div class="d-flex justify-space-between align-center my-4">
            <v-btn color="red"
                   @click="voting_station.blank_papers > 0 ? voting_station.blank_papers-- : null">-
            </v-btn>
            <h3>{{ voting_station.blank_papers }}</h3>
            <v-btn color="blue" @click="voting_station.blank_papers++">+</v-btn>
          </div>
        </v-card-item>
        <v-card-actions>
          <button class="ma-auto final" @click="updateBlankPapers">Conferma</button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="null_modal">
      <v-card>
        <v-card-title>Modifica schede nulle</v-card-title>
        <v-card-item>
          Il numero di schede nulle nel tuo seggio è {{ voting_station.null_papers }}. Vuoi modificarlo?
          <div class="d-flex justify-space-between align-center my-4">
            <v-btn color="red"
                   @click="voting_station.null_papers > 0 ? voting_station.null_papers-- : null">-
            </v-btn>
            <h3>{{ voting_station.null_papers }}</h3>
            <v-btn color="blue" @click="voting_station.null_papers++">+</v-btn>
          </div>
        </v-card-item>
        <v-card-actions>
          <button class="ma-auto final" @click="updateNullPapers">Conferma</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {useGroupsStore} from "@/stores/groups";
import {useCouncilorsStore} from "@/stores/councilors";

export default {
  name: "LivePage",
  setup() {
    const groupsStore = useGroupsStore();
    const councilorsStore = useCouncilorsStore();

    return {groupsStore, councilorsStore};
  },
  data() {
    return {
      voting_station: null,
      turn: null,
      majors: null,
      groups: {},
      councilors: [],
      major_groups: true,
      major_list: null,
      vote: {
        voting_station_id: this.$route.params.votingStationId,
        major_id: null,
        group_id: null,
        councilors: []
      },
      votes_queue: JSON.parse(localStorage.getItem('votes_queue')) || [],
      waiting: false,
      asking: false,
      blank_modal: false,
      null_modal: false
    }
  },
  methods: {
    selectMajor(id) {
      if (this.turn.majors_only) {
        this.vote.major_id = id;
        this.asking = true;
        return;
      }

      if (this.vote.group_id && id !== this.vote.major_id) {
        this.major_groups = this.selected_group.major.id === id;
      }

      this.vote.major_id = id;
    },
    selectGroup(id) {
      if (this.vote.group_id === id) {
        this.vote.group_id = null;
        return;
      }

      this.vote.group_id = id;
      this.councilorsStore.getOfGroup(id)
          .then(councilors => this.councilors = councilors);
    },
    addCouncilor(councilor) {
      if (this.vote.councilors.length === 0) {
        this.vote.councilors.push(councilor.id);
        return;
      }

      if (this.vote.councilors.includes(councilor.id)) {
        this.vote.councilors.splice(this.vote.councilors.indexOf(councilor.id), 1);
        return;
      }

      for (let councilor_id of this.vote.councilors) {
        let selected_councilor = this.councilors.find(c => c.id === councilor_id);
        if (selected_councilor.sex === councilor.sex) {
          this.vote.councilors.splice(this.vote.councilors.indexOf(councilor_id), 1);
          this.vote.councilors.push(councilor.id);
          return;
        }
      }

      this.vote.councilors.push(councilor.id);
    },
    reset() {
      this.vote.councilors.splice(0, 2);
      this.vote.group_id = null;
      this.vote.major_id = null;
      this.major_groups = true;
      this.councilors = [];
    },
    send() {
      this.asking = false;

      this.$nextTick(() => {
        let vote = {
          voting_station_id: this.vote.voting_station_id,
          major_id: this.vote.major_id,
        };

        if (this.vote.group_id) vote.group_id = this.vote.group_id;
        if (this.vote.councilors.length) vote.councilors = [...this.vote.councilors];

        this.votes_queue.push(vote);
        localStorage.setItem('votes_queue', JSON.stringify(this.votes_queue));
        this.reset();
      })
    },
    updateBlankPapers() {
      this.axios.post(`/voting_stations/${this.voting_station.id}/blank_paper`, {num: this.voting_station.blank_papers})
          .then(() => this.blank_modal = false);
    },
    updateNullPapers() {
      this.axios.post(`/voting_stations/${this.voting_station.id}/null_paper`, {num: this.voting_station.null_papers})
          .then(() => this.null_modal = false);
    },
    closeVotingStation() {
      this.axios.post(`/voting_stations/${this.voting_station.id}/close`)
          .then(res => res.data)
          .then(res => this.voting_station = res.data);
    }
  },
  beforeMount() {
    this.axios.get(`voting_stations/${this.$route.params.votingStationId}`)
        .then(res => res.data)
        .then(res => {
          this.voting_station = res.data;
          this.turn = this.voting_station.turn;
          this.majors = this.turn.majors;

          for (let major of this.majors) {
            this.groupsStore.getOfMajor(major.id)
                .then(groups => this.groups[major.id] = groups);
          }
        })
  },
  mounted() {
    setInterval(() => {
      if (this.waiting || this.votes_queue.length === 0) return;
      this.waiting = true;

      this.axios.post(`turns/${this.turn.id}/votes`, this.votes_queue[0])
          .then(() => {
            this.votes_queue.shift();
            localStorage.setItem('votes_queue', JSON.stringify(this.votes_queue));
            this.waiting = false;
          })
          .catch((e) => {
            console.log(e);
            this.waiting = false;
          })
    }, 1000);
  },
  computed: {
    selected_major() {
      if (this.vote.major_id === null) return null;
      return this.majors.find(m => m.id === this.vote.major_id);
    },
    selected_group() {
      if (this.vote.group_id === null) return null;
      if (this.major_groups) return Object.values(this.groups[this.vote.major_id]).find(g => g.id === this.vote.group_id);

      for (let groups of Object.values(this.groups)) {
        let group = Object.values(groups).find(g => g.id === this.vote.group_id);

        if (group) return group;
      }

      return null;
    },
    selected_councilors() {
      let councilors = [];

      for (let councilor_id of this.vote.councilors) {
        councilors.push(this.councilors.find(c => c.id === councilor_id));
      }

      return councilors
    },
    maleCouncilors() {
      if (this.councilors === null) return null;
      return this.councilors.filter(c => c.sex === 'm').sort((a, b) => a.surname.localeCompare(b.surname))
    },
    femaleCouncilors() {
      if (this.councilors === null) return null;
      return this.councilors.filter(c => c.sex === 'f').sort((a, b) => a.surname.localeCompare(b.surname))
    }
  }
}
</script>

<style scoped>

</style>
