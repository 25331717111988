import { defineStore } from 'pinia'
import axios from "axios";

export const useCouncilorsStore = defineStore('councilors', {
    state: () => ({
        group_councilors: {},
    }),
    getters: {

    },
    actions: {
        getOfGroup(id){
            return new Promise((resolve) => {
                if (id in this.group_councilors)
                    return resolve(this.group_councilors[id]);

                axios.get(`groups/${id}/councilors`)
                    .then(res => res.data)
                    .then(res => {
                        this.group_councilors[id] = res.data;
                        resolve(this.group_councilors[id]);
                    })
            })
        },
        add(group_id, councilor_values){
            const formData = new FormData();
            formData.append("name", councilor_values.name);
            formData.append("surname", councilor_values.surname);
            formData.append("sex", councilor_values.sex);

            return axios.post(`groups/${group_id}/councilors`, formData)
                .then(res => res.data)
                .then(res => {
                    this.group_councilors[group_id].push(res.data)
                    return res.data
                });
        },
        update(councilor_id, councilor_values) {
            const formData = new FormData();
            formData.append("name", councilor_values.name);
            formData.append("surname", councilor_values.surname);
            formData.append("sex", councilor_values.sex);
            formData.append("_method", "PUT");

            return axios.post(`councilors/${councilor_id}`, formData)
                .then(res => res.data)
                .then(res => {
                    delete this.group_councilors[res.data.group.id];
                    this.getOfGroup(res.data.group.id);
                    return res.data;
                });
        },
        delete(group_id, councilor_id){
            return axios.delete(`councilors/${councilor_id}`)
                .then(() => this.group_councilors[group_id].splice(this.group_councilors[group_id].indexOf(councilor => councilor.id === councilor_id), 1));
        }
    }
});
