import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from "@/components/pages/LoginPage.vue";
import DashboardPage from "@/components/pages/DashboardPage.vue";
import ElectionPage from "@/components/pages/ElectionPage.vue";
import TurnsPage from "@/components/pages/Turns/TurnsPage.vue";
import VotingStationsPage from "@/components/pages/VotingStationsPage.vue";
import ListsPage from "@/components/pages/Lists/ListsPage.vue";
import InvitedPage from "@/components/pages/InvitedPage.vue";
import LivePage from "@/components/pages/Live/LivePage.vue";
import AlignmentPage from "@/components/pages/Live/AlignmentPage.vue";

const routes = [
    {
        name: 'login',
        path: '/',
        component: LoginPage,
        meta: {
            layout: 'AppLayout'
        }
    },
    {
        name: 'invited',
        path: '/invited/:token',
        component: InvitedPage,
        meta: {
            layout: 'AppLayout',
            middleware: 'invited'
        }
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: DashboardPage,
        meta: {
            layout: 'HomeLayout',
            middleware: 'auth'
        },
    },
    {
        name: 'election',
        path: '/election/:electionId',
        component: ElectionPage,
        meta: {
            layout: 'ElectionLayout',
            middleware: 'auth'
        }
    },
    {
        name: 'turns',
        path: '/election/:electionId/turns',
        component: TurnsPage,
        meta: {
            layout: 'ElectionLayout',
            middleware: 'auth'
        }
    },
    {
        name: 'voting_stations',
        path: '/election/:electionId/turns/:turnId/voting_stations',
        component: VotingStationsPage,
        meta: {
            layout: 'ElectionLayout',
            middleware: 'auth'
        }
    },
    {
        name: 'lists',
        path: '/election/:electionId/lists',
        component: ListsPage,
        meta: {
            layout: 'ElectionLayout',
            middleware: 'auth'
        }
    },
    {
        name: 'live',
        path: '/live/:votingStationId',
        component: LivePage,
        meta: {
            layout: 'AppLayout',
            middleware: 'auth'
        }
    },
    {
        name: 'alignment',
        path: '/live/:votingStationId/alignment',
        component: AlignmentPage,
        meta: {
            layout: 'AppLayout',
            middleware: 'auth'
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to) => {
    const meta_middleware = to?.meta?.middleware;

    if (meta_middleware){
        let middlewares = meta_middleware.split('|');

        for (let middleware of middlewares){
            import('./middleware/' + middleware + '.js')
                .then((module) => {
                    module.check();
                })
        }
    }
});

export default router;
