<template>
  <div>
      <v-card class="ma-4" elevation="0">
          <v-card-title class="font-weight-bold" v-if="!election">Caricamento...</v-card-title>
          <template v-else>
              <v-card-title><span class="font-weight-bold">{{ election.name }}</span> / {{election.city.name}}</v-card-title>
              <v-card-subtitle><a :href="'https://' + election.slug + '.spogliolive.it'" target="_blank">{{election.slug}}.spogliolive.it</a></v-card-subtitle>
              <v-card-item>
                  <div class="ma-2">
                      <v-file-input
                          v-if="selected_city"
                          label="Immagine"
                          variant="outlined"
                          prepend-icon="fa:fas fa-camera"
                          v-model="image"
                          :rules="[rules.required]"
                          :disabled="saving_election"
                      ></v-file-input>
                      <v-text-field label="Nome"
                                    variant="outlined"
                                    v-model="election.name"
                                    :rules="[rules.required]"
                                    :disabled="saving_election"
                      ></v-text-field>
                      <v-select
                          v-if="cities"
                          v-model="election.city"
                          variant="outlined"
                          :items="cities"
                          item-title="name"
                          item-value="id"
                          label="Città"
                          return-object
                          single-line
                          :disabled="saving_election"
                      ></v-select>
                      <v-text-field label="Sindaco in carica"
                                    v-if="selected_city"
                                    variant="outlined"
                                    v-model="selected_city.current_major"
                                    :disabled="saving_election"
                      ></v-text-field>
                      <v-btn class="mr-auto" color="blue" @click="save">Salva</v-btn>
                  </div>
              </v-card-item>
          </template>
      </v-card>
  </div>
</template>

<script>
import {useElectionsStore} from "@/stores/elections";
import axios from "axios";
import {useUserStore} from "@/stores/user";

export default {
    name: "ElectionPage",
    setup() {
      const electionsStore = useElectionsStore();
      const userStore = useUserStore();

      return { electionsStore, userStore }
    },
    data() {
        return {
            election: null,
            saving_election: false,
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
            cities: null,
            image: null
        }
    },
    beforeMount() {
        this.electionsStore.find(parseInt(this.$route.params.electionId))
            .then(election => this.election = election);
        axios.get('/cities')
            .then(res => res.data)
            .then(res => this.cities = res.data);
    },
    methods: {
        save() {
            this.saving_election = true;

            const formData = new FormData();
            formData.append('name', this.selected_city.name);
            formData.append('current_major', this.selected_city.current_major);
            formData.append('_method', "PATCH");

            if (this.image) formData.append('image', this.image[0]);

            axios.post('/cities/' + this.selected_city.id, formData);

            this.userStore.getUser()
                .then(u => {
                    axios.patch('/elections/' + this.election.id, {
                        name: this.election.name,
                        slug: this.election.slug,
                        user_id: u.id,
                        city_id: this.election.city.id
                    })
                        .then(() => this.saving_election = false);
                });

        }
    },
    computed: {
        selected_city() {
            if (!this.cities) return null;
            return this.cities.find(c => c.id === this.election.city.id);
        }
    }
}
</script>

<style scoped>

</style>
