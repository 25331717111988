<template>
  <div class="my-5 pa-5 station">
    <h3>{{ station.name }}</h3>
    <div v-if="edit" class="mt-2">
      <v-text-field label="Email"
                    variant="outlined"
                    v-model="email"
                    clearable
      ></v-text-field>
    </div>
    <p v-else>
      <template v-if="voting_station && voting_station.user">
        Assegnato a {{ voting_station.user.email }} ({{voting_station.voters}} votanti)
      </template>
      <template v-else>
        Non assegnato.
      </template>
    </p>
    <template v-if="!edit">
      <v-btn @click="edit = true" class="mr-4">Assegna</v-btn>
      <template v-if="voting_station">
        <router-link :to="{ name: 'alignment', params: { votingStationId: voting_station.id }}">
          <v-btn color="blue" class="mr-4">Gestisci</v-btn>
        </router-link>
        <v-btn color="green" @click="modal = true">Votanti</v-btn>
      </template>
    </template>
    <v-btn v-else @click="assignStation">Conferma</v-btn>
  </div>
  <v-dialog v-model="modal" max-width="500">
    <v-card>
      <v-card-title>Imposta votanti sezione {{ voting_station.name }}</v-card-title>
      <v-card-item>
        <div class="d-flex justify-space-between align-center my-4">
          <v-text-field v-model="voters" variant="outlined" label="Votanti" />
        </div>
      </v-card-item>
      <v-card-actions>
        <button class="ma-auto final" @click="setVoters">Conferma</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {useVotingStationsStore} from "@/stores/voting_stations";

export default {
  name: "StationCard",
  props: ["station", "turn_id", "voting_station"],
  setup() {
    const votingStationStore = useVotingStationsStore();

    return {votingStationStore}
  },
  data() {
    return {
      edit: false,
      email: this.voting_station?.user?.email,
      searchTimeout: null,
      searchResults: null,
      modal: false,
      voters: this.voting_station?.voters
    }
  },
  methods: {
    assignStation() {
      if (this.voting_station)
        this.votingStationStore.update(this.voting_station.id, {
          station_id: this.station.id,
          email: this.email,
          turn_id: this.turn_id
        }).then(res => this.email = res.user.email);
      else
        this.votingStationStore.add(this.station.id, this.turn_id, this.email)

      this.edit = false;
    },
    setVoters() {
      this.votingStationStore.update(this.voting_station.id, {
        station_id: this.station.id,
        email: this.email,
        turn_id: this.turn_id,
        voters: this.voters
      }).then(() => this.modal = false);
    }
  }
}
</script>
