<template>
    <div class="w-100">
        <v-expansion-panel v-if="!edit">
            <v-expansion-panel-title @click.once="expanded = !expanded">
                <div class="d-flex justify-space-between align-center w-100">
                    <div class="d-flex align-center" style="gap: 10px">
                        <v-avatar :image="value.image"></v-avatar>
                        <p class="ma-0">{{value.number}} {{ value.name }}</p>
                    </div>
                    <div class="d-flex mr-2" style="gap: 10px">
                        <v-btn size="x-small" icon="fa:fas fa-pencil-alt" color="yellow"
                               @click.stop="edit = !edit"></v-btn>
                        <v-btn size="x-small" icon="fa:fas fa-trash" color="red" @click.stop="deleting = true"></v-btn>
                    </div>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <template v-if="expanded">
                    <div v-if="loading_councilors">
                        Caricamento liste...
                    </div>
                    <div v-else-if="!councilors || !councilors.length">
                        Non ci sono consiglieri in lista
                    </div>
                    <div v-else>
                        <councilor-row v-for="(councilor, key) in councilors" :value="councilor" :key="key"></councilor-row>
                    </div>
                    <div class="text-center" v-if="!creating">
                        <v-btn color="blue" prepend-icon="fa:fas fa-plus" class="ma-auto mt-4" @click="creating = true">Aggiungi consigliere</v-btn>
                    </div>
                    <div v-else>
                        <handle-councilor-values :creating="true" @update:modelValue="createCouncilor"></handle-councilor-values>
                    </div>
                </template>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <div v-else>
            <handle-group-values v-model="group_values" :saving="saving" @save="update"></handle-group-values>
        </div>
        <v-dialog v-model="deleting">
            <v-card>
                <v-card-title>Sei sicuro di voler cancellare la lista {{value.name}}?</v-card-title>
                <v-card-text>
                    Attenzione: l'operazione non è reversibile.
                    Cancellando la lista selezionata, eliminerai anche i candidati collegati.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="deleting = false">Chiudi</v-btn>
                    <v-btn color="blue" @click="deleteGroup">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>

</style>

<script>
import {useCouncilorsStore} from "@/stores/councilors";
import {useGroupsStore} from "@/stores/groups";
import HandleCouncilorValues from "@/components/pages/Lists/HandleCouncilorValues.vue";
import CouncilorRow from "@/components/pages/Lists/CouncilorRow.vue";
import HandleGroupValues from "@/components/pages/Lists/HandleGroupValues.vue";

export default {
    name: "GroupRow",
    components: {HandleGroupValues, CouncilorRow, HandleCouncilorValues},
    props: {
        value: Object,
    },
    setup() {
        const groupsStore = useGroupsStore();
        const councilorStore = useCouncilorsStore();

        return {groupsStore, councilorStore};
    },
    data() {
        return {
            expanded: false,
            edit: false,
            loading_councilors: false,
            group_values: {
                image: this.value.image,
                number: this.value.number,
                name: this.value.name
            },
            deleting: false,
            creating: false
        }
    },
    methods: {
        update() {
            this.groupsStore.update(this.value.id, this.group_values)
                .then(() => this.edit = false);
        },
        deleteGroup() {
            this.groupsStore.delete(this.value.major.id, this.value.id);
        },
        createCouncilor(councilor_values){
            this.councilorStore.add(this.value.id, councilor_values)
                .then(() => this.creating = false);
        }
    },
    computed: {
        councilors() {
            return this.councilorStore.group_councilors[this.value.id];
        }
    },
    watch: {
        expanded() {
            if (this.expanded && !this.councilors){
                this.councilorStore.getOfGroup(this.value.id);
            }
        }
    }
}
</script>
