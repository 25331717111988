import { defineStore } from 'pinia'
import axios from "axios";
import {watch} from "vue";

export const useMajorsStore = defineStore('majors', {
    state: () => ({
        majors: null,
        gettingMajors: false,
    }),
    getters: {

    },
    actions: {
        get(id){
            return new Promise((resolve, reject) => {
                if (this.majors) return resolve(this.majors);

                if (this.gettingMajors){
                    const unwatch = watch(() => this.gettingMajors, newVal => {
                        if (newVal){
                            unwatch();
                            return resolve(this.majors);
                        }
                    }, { deep: true })
                }

                this.gettingMajors = true;
                axios.get('/elections/' + id + '/majors')
                    .then(res => res.data)
                    .then(res => this.majors = res.data)
                    .then(() => resolve(this.majors))
                    .then(() => this.gettingMajors = false)
                    .catch(e => reject(e));
            })
        },
        update(majorValues){
            const formData = new FormData();
            formData.append("name", majorValues.name);
            formData.append("surname", majorValues.surname);
            formData.append("_method", "PUT");

            if (majorValues.image !== null) formData.append("image", majorValues.image[0]);

            return axios.post("majors/" + majorValues.id, formData);
        },
        delete(major_id){
            return axios.delete(`majors/${major_id}`)
                .then(() => this.majors.splice(this.majors.indexOf(major => major.id === major_id), 1));
        },
        add(election_id, majorValues){
            const formData = new FormData();
            formData.append("name", majorValues.name);
            formData.append("surname", majorValues.surname);
            formData.append("image", majorValues.image[0]);

            return axios.post('elections/' + election_id + '/majors', formData)
                .then(res => res.data)
                .then(() => {
                    this.refresh(election_id)
                });
        },
        refresh(id){
            this.majors = null;
            return this.get(id);
        }
    }
})
