<template>
    <div class="w-100 py-2" style="border-bottom: 1px solid lightgray">
        <div class="d-flex justify-space-between align-center w-100" v-if="!edit">
            <div class="d-flex align-center" style="gap: 10px">
                <p class="ma-0">{{ value.name }} {{value.surname}}, {{value.sex}}</p>
            </div>
            <div class="d-flex mr-2" style="gap: 10px">
                <v-btn size="x-small" icon="fa:fas fa-pencil-alt" color="yellow"
                       @click.stop="edit = !edit"></v-btn>
                <v-btn size="x-small" icon="fa:fas fa-trash" color="red" @click.stop="deleting = true"></v-btn>
            </div>
        </div>
        <div v-else>
            <handle-councilor-values v-model="councilor_values" @save="update"></handle-councilor-values>
        </div>
        <v-dialog v-model="deleting">
            <v-card>
                <v-card-title>Sei sicuro di voler cancellare il candidato {{value.name}} {{value.surname}}?</v-card-title>
                <v-card-text>
                    Attenzione: l'operazione non è reversibile.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="deleting = false">Chiudi</v-btn>
                    <v-btn color="blue" @click="deleteCouncilor">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>

</style>

<script>
import {useCouncilorsStore} from "@/stores/councilors";
import HandleCouncilorValues from "@/components/pages/Lists/HandleCouncilorValues.vue";

export default {
    name: "CouncilorRow",
    components: {HandleCouncilorValues},
    props: {
        value: Object,
    },
    setup() {
        const councilorStore = useCouncilorsStore();

        return {councilorStore};
    },
    data() {
        return {
            expanded: false,
            edit: false,
            councilor_values: {
                image: this.value.image,
                name: this.value.name,
                surname: this.value.surname,
                sex: this.value.sex,
            },
            deleting: false,
            creating: false
        }
    },
    methods: {
        update() {
            this.councilorStore.update(this.value.id, this.councilor_values)
                .then(() => this.edit = false);
        },
        deleteCouncilor() {
            this.councilorStore.delete(this.value.group.id, this.value.id);
        }
    },
    computed: {
        councilors() {
            return this.councilorStore.group_councilors[this.value.id];
        }
    }
}
</script>
