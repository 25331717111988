<template>
    <component :is="layout" style="min-height: 100vh" class="bg-blue-grey-lighten-5">
        <router-view />
    </component>
</template>

<script>
import {useRoute} from "vue-router";
import AppLayout from "@/components/layouts/AppLayout.vue";
import HomeLayout from "@/components/layouts/HomeLayout.vue";
import ElectionLayout from "@/components/layouts/ElectionLayout.vue";

export default {
    name: "LayoutManager",
    components: {
        HomeLayout, AppLayout, ElectionLayout
    },
    computed: {
        layout() {
            const route = useRoute();
            const layout = route?.meta?.layout;

            if (layout)
                return layout;

            return 'AppLayout';
        }
    }
}
</script>

<style scoped>

</style>
