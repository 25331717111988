import { defineStore } from 'pinia'
import axios from "axios";
import {useUserStore} from "@/stores/user";

export const useVotingStationsStore = defineStore('voting_stations', {
    state: () => ({
        voting_stations: {},
    }),
    getters: {

    },
    actions: {
        getOfUser() {
            return useUserStore().getUser()
                .then(user => {
                    return axios.get(`users/${user.id}/voting_stations`)
                        .then(res => res.data)
                        .then(res => res.data);
                });
        },
        getOfTurn(id){
            return new Promise((resolve) => {
                if (id in this.voting_stations)
                    return resolve(this.voting_stations[id]);

                axios.get(`turns/${id}/voting_stations`)
                    .then(res => res.data)
                    .then(res => {
                        this.voting_stations[id] = res.data;
                        resolve(this.voting_stations[id]);
                    })
            })
        },
        add(station_id, turn_id, email){
            return axios.post(`turns/${turn_id}/voting_stations`, { station_id, email })
                .then(res => res.data)
                .then(res => {
                    this.voting_stations[turn_id].push(res.data)
                    return res.data
                });
        },
        update(voting_station_id, voting_station_values) {
            return axios.patch(`voting_stations/${voting_station_id}`, voting_station_values)
                .then(res => res.data)
                .then(res => {
                    delete this.voting_stations[res.data.turn.id];
                    this.getOfTurn(res.data.turn.id);
                    return res.data;
                });
        },
        delete(turn_id, voting_station_id){
            return axios.delete(`voting_stations/${voting_station_id}`)
                .then(() => this.voting_stations[turn_id].splice(this.voting_stations[turn_id].indexOf(voting_station => voting_station.id === voting_station_id), 1));
        }
    }
});
