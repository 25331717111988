<template>
    <v-main class="my-auto">
        <v-card class="mx-auto px-2 py-2" max-width="344" elevation="3">
            <v-card-item>
                <v-card-title class="text-center mt-2">
                    <img src="../../assets/logo.svg" alt="Spogliolive" class="ma-auto">
                </v-card-title>
            </v-card-item>
            <v-card-item>
                <div class="mt-2">
                    <v-text-field clearable
                                  label="Email"
                                  variant="outlined"
                                  v-model="email"
                                  :rules="[rules.required]"
                                  :disabled="logging"
                    ></v-text-field>
                    <v-text-field clearable
                                  label="Password"
                                  variant="outlined"
                                  type="password"
                                  v-model="password"
                                  :rules="[rules.required]"
                                  :disabled="logging"
                    ></v-text-field>
                </div>
            </v-card-item>
            <v-card-actions>
                <v-btn class="mx-auto bg-blue-darken-1 text-none font-weight-bold" @click="login">
                    Login
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-main>
</template>

<script>
import {useUserStore} from "@/stores/user";

export default {
    name: "LoginPage",
    setup() {
        const userStore = useUserStore();

        return {userStore};
    },
    data() {
        return {
            email: null,
            password: null,
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
            logging: false,
        }
    },
    beforeMount() {
      if (this.userStore.isLogged)
          this.$router.push({ name: 'dashboard' });
    },
    methods: {
        login() {
            if (this.logging) return;

            this.logging = true;
            let formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);
            this.axios.post("/login", formData)
                .then(response => response.data)
                .then(data => this.userStore.setToken(data.token))
                .then(() => this.$router.push({ name: 'dashboard' }))
                .catch(() => this.logging = false);
        }
    }
}
</script>

<style scoped>

</style>
