<template>
    <v-layout>
        <v-app-bar elevation="2">
            <template v-slot:prepend>
                <img src="../../assets/logo.svg" alt="Spogliolive">
            </template>
            <template v-slot:append v-if="user">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            v-bind="props"
                        >
                            <v-icon icon="fa:fas fa-user" class="mr-2"></v-icon> {{ user.name }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title @click="logout()">
                                Logout
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <v-main>
            <slot></slot>
        </v-main>
    </v-layout>
</template>

<script>
import {useUserStore} from "@/stores/user";

export default {
    name: "HomeLayout",
    setup() {
        const userStore = useUserStore();

        return { userStore };
    },
    data() {
        return {
            user: null
        }
    },
    beforeMount() {
        this.userStore.getUser()
            .then(user => this.user = user);
    },
    methods: {
        logout() {
            this.userStore.logout();
            this.$router.push({ name: 'login' });
        }
    }
}
</script>

<style scoped>

</style>
