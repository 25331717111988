<template>
    <div class="d-flex justify-space-between align-center my-2" style="gap: 20px">
        <v-text-field label="Nome"
                      variant="outlined"
                      v-model="group.name"
        ></v-text-field>
        <v-text-field label="Cognome"
                      variant="outlined"
                      v-model="group.surname"
        ></v-text-field>
        <v-select
            label="Sesso"
            :items="['m', 'f']"
            v-model="group.sex"
            variant="outlined"
        ></v-select>

        <v-btn color="blue" @click="save">Salva</v-btn>
        <v-btn v-if="creating" @click="$emit('close')">Chiudi</v-btn>
    </div>
</template>


<script>
export default {
    name: "HandleCouncilorValues",
    props: ["modelValue", "creating"],
    data() {
        return {
            group: this.modelValue,
            imageUrl: this.modelValue?.image
        }
    },
    beforeMount() {
        if (this.creating) {
            this.group = {
                name: null,
                surname: null,
                sex: null,
                image: null
            }
        }

        this.group.image = null;
    },
    methods: {
        save() {
            this.$emit('update:modelValue', this.group);
            this.$emit('save');
        }
    }
}
</script>
