import { defineStore } from 'pinia'
import axios from "axios";
import {watch} from "vue";
import {useElectionsStore} from "@/stores/elections";
import {useVotingStationsStore} from "@/stores/voting_stations";

export const useUserStore = defineStore('user', {
    state: () => ({
        token: null,
        user: null,
        gettingUser: false
    }),
    getters: {
      isLogged() {
          return !!this.token;
      }
    },
    actions: {
        checkLogin(){
            let token = localStorage.getItem('token');
            if (token) {
                this.setToken(token);
            }
        },
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', this.token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            this.getUser()
                .catch(() => {
                    this.token = null;
                });
        },
        getUser() {
            return new Promise((resolve, reject) => {
                console.log("getting user");
                if (this.user) return resolve(this.user);


                if (this.gettingUser){
                    // Imposta il watch sulla proprietà dello store
                    const unwatch = watch(
                        () => this.gettingUser,
                        (newValue) => {
                            if (!newValue)
                                return resolve(this.user);

                            unwatch();
                        }, {deep: true}
                    );
                } else {
                    console.log("making request");
                    this.gettingUser = true;
                    axios.get('/users/logged')
                        .then(res => {
                            this.user = res.data.data;
                            this.gettingUser = false;
                            return resolve(this.user);
                        }).catch(() => reject());
                }
            })
        },
        logout(){
            this.token = null;
            this.user = null;
            localStorage.removeItem('token');
            axios.defaults.headers.common['Authorization'] = null;

            useElectionsStore().elections = null;
            useVotingStationsStore().voting_stations = {};
        },
        getInvited(token){
            return axios.get(`users/invited/${token}`)
                .then(res => res.data)
                .then(res => res.data);
        }
    }
})
