<template>
    <div class="d-flex justify-space-between align-center my-2" style="gap: 20px">
        <label style="width: 50px; aspect-ratio: 1" class="border-dotted border-2 d-flex align-center justify-center">
            <input type="file" ref="fileInput" accept="image/*" @change="onFileSelected" style="display: none">
            <v-avatar v-if="imageUrl" :image="imageUrl" style="cursor: pointer"></v-avatar>
        </label>
        <v-text-field label="Numero"
                      type="number"
                      variant="outlined"
                      v-model="group.number"
                      :disabled="saving"
        ></v-text-field>
        <v-text-field label="Nome"
                      variant="outlined"
                      v-model="group.name"
                      :disabled="saving"
        ></v-text-field>

        <v-btn color="blue" @click="save">Salva</v-btn>
        <v-btn v-if="creating" @click="$emit('close')">Chiudi</v-btn>
    </div>
</template>


<script>
export default {
    name: "HandleGroupValues",
    props: ["modelValue", "creating"],
    data() {
        return {
            group: this.modelValue,
            imageUrl: this.modelValue?.image
        }
    },
    beforeMount() {
        if (this.creating) {
            this.group = {
                name: null,
                number: null,
                image: null
            }
        }

        this.group.image = null;
    },
    methods: {
        save() {
            this.$emit('update:modelValue', this.group);
            this.$emit('save');
        },
        onFileSelected(event) {
            this.group.image = event.target.files;
            const file = this.group.image[0];
            if (!file.type.startsWith('image/')) {
                alert('Selezionare solo file di immagine.');
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                this.imageUrl = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    }
}
</script>
