import { defineStore } from 'pinia'
import axios from "axios";
import {watch} from "vue";

export const useTurnsStore = defineStore('turns', {
    state: () => ({
        turns: null,
        gettingTurns: false,
    }),
    getters: {

    },
    actions: {
        getOfElection(id){
            return new Promise((resolve, reject) => {
                if (this.turns) return resolve(this.turns);

                if (this.gettingTurns){
                    const unwatch = watch(() => this.gettingTurns, newVal => {
                        if (newVal){
                            unwatch();
                            return resolve(this.turns);
                        }
                    }, { deep: true })
                }

                this.gettingTurns = true;
                axios.get('/elections/' + id + '/turns')
                    .then(res => res.data)
                    .then(res => this.turns = res.data)
                    .then(() => resolve(this.turns))
                    .then(() => this.gettingTurns = false)
                    .catch(e => reject(e));
            })
        },
        refresh(id) {
            this.turns = null;
            return this.getOfElection(id);
        },
        add(election_id, values) {
            return axios.post('elections/' + election_id + '/turns', {
                name: values.name,
                started_at: values.started_at.replace('T', ' ') + ':00',
                finished_at: values.finished_at.replace('T', ' ') + ':00',
                majors_only: values.majors_only,
                majors: values.majors
            })
                .then(res => res.data)
                .then(() => {
                    this.refresh(election_id)
                });
        },
        update(id, values) {
            return axios.patch(`/turns/${id}`, {
                name: values.name,
                started_at: this.formatDate(values.started_at),
                finished_at: this.formatDate(values.finished_at),
                majors_only: values.majors_only,
                majors: values.majors,
            })
        },
        deleteTurn(id){
            return axios.delete(`/turns/${id}`);
        },
        formatDate(date_string){
            let dateParts
            if (date_string.includes("T"))
                dateParts = date_string.split("T");
            else
                dateParts = date_string.split(" ");

            const date = dateParts[0];
            const time = dateParts[1].split(".")[0];
            const dateTimeString = `${date}T${time}Z`;
            const dateTime = new Date(dateTimeString);

            const year = dateTime.getFullYear();
            const month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
            const day = ("0" + dateTime.getDate()).slice(-2);
            const hour = ("0" + dateTime.getHours()).slice(-2);
            const minute = ("0" + dateTime.getMinutes()).slice(-2);
            const second = ("0" + dateTime.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        }
    }
})
