<template>
    <div class="pa-5 text-left">
        <p class="text-h3 font-weight-bold">Dashboard</p>
        <div class="my-5">
            <p class="text-h5 ">Elezioni di cui sei amministratore</p>
            <div class="mt-5">
                <template v-if="elections == null">
                    <v-card max-width="400px" text="Caricamento...">
                    </v-card>
                </template>
                <template v-else-if="elections.length === 0">
                    <v-card max-width="400px" text="Nessuna elezione trovata">

                    </v-card>
                </template>
                <template v-else>
                    <v-card v-for="election in elections" :key="election.id" max-width="400px">
                        <v-card-title>{{ election.name }}</v-card-title>
                        <v-card-subtitle>{{ election.city.name }} / {{ election.slug }}</v-card-subtitle>
                        <v-card-actions>
                            <router-link :to="{name: 'election', params: { electionId: election.id }}">
                                <v-btn>Apri</v-btn>
                            </router-link>
                        </v-card-actions>
                    </v-card>
                </template>
            </div>
        </div>
        <hr>
        <div class="mt-5">
            <p class="text-h5">Sezioni a te assegnate</p>
            <div v-if="voting_stations !== null" class="d-flex" style="gap: 10px">
                <voting-station-card v-for="(voting_station, key) in voting_stations" :key="key" :voting_station="voting_station"></voting-station-card>
            </div>
        </div>
    </div>
</template>

<script>

import {useElectionsStore} from "@/stores/elections";
import {useVotingStationsStore} from "@/stores/voting_stations";
import VotingStationCard from "@/components/pages/VotingStationCard.vue";

export default {
    name: "DashboardPage",
    components: {VotingStationCard},
    setup() {
        const electionsStore = useElectionsStore();
        const votingStationsStore = useVotingStationsStore();

        return {electionsStore, votingStationsStore};
    },
    data() {
        return {
            elections: null,
            voting_stations: null
        }
    },
    beforeMount() {
        this.electionsStore.getOfUser()
            .then(elections => this.elections = elections);
        this.votingStationsStore.getOfUser()
            .then(voting_stations => this.voting_stations = voting_stations);
    }
}
</script>

<style scoped>

</style>
