import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from "@/router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGates from 'vue-gates'
import {useUserStore} from "@/stores/user";
import "./style/app.scss";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'


const vuetify = createVuetify({
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa
        }
    },
    components,
    directives,
})

const pinia = createPinia()

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App)
    .use(vuetify)
    .use(router)
    .use(VueAxios, axios)
    .use(pinia)
    .use(VueGates)
    .mount('#app');

useUserStore().checkLogin();
