<template>
    <v-dialog v-model="modal">
        <v-card>
            <v-card-title>
                <template v-if="creating">
                    Crea un nuovo turno
                </template>
                <template v-else>
                    Modifica {{turn.name}}
                </template>
            </v-card-title>
            <v-card-item>
                <div class="my-2">
                    <v-text-field label="Nome"
                                  variant="outlined"
                                  v-model="turn.name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field label="Partenza"
                                  type="datetime-local"
                                  variant="outlined"
                                  v-model="turn.started_at"
                                  :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field label="Scadenza"
                                  type="datetime-local"
                                  variant="outlined"
                                  v-model="turn.finished_at"
                                  :rules="[rules.required]"
                    ></v-text-field>
                    <v-checkbox v-model="turn.majors_only" label="Solo sindaci"></v-checkbox>
                    <div>
                        <p>Sindaci nel turno</p>
                        <div v-for="(major, key) in majors" :key="key">
                            <v-checkbox v-model="turn.majors" :value="major.id">
                                <template v-slot:label>
                                    <v-avatar :image="major.image"></v-avatar>
                                    <p>{{major.name}} {{major.surname}}</p>
                                </template>
                            </v-checkbox>
                        </div>
                    </div>
                </div>
            </v-card-item>
            <v-card-actions>
                <v-btn @click="$emit('close')">Chiudi</v-btn>
                <v-btn @click="$emit('save')">
                    <template v-if="creating">
                        Crea
                    </template>
                    <template v-else>
                        Salva
                    </template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {useMajorsStore} from "@/stores/majors";
import {useTurnsStore} from "@/stores/turns";

export default {
    name: "TurnModal",
    props: {
        create_modal: Boolean,
        modelValue: {
            name: String,
            started_at: String,
            finished_at: String,
            majors_only: Boolean,
            majors: Array
        },
        creating: Boolean
    },
    setup() {
        const majorsStore = useMajorsStore();
        const turnsStore = useTurnsStore();

        return { majorsStore, turnsStore };
    },
    data() {
        return {
            turn: this.modelValue,
            modal: this.create_modal,
            majors: null,
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
        }
    },
    beforeMount() {
        this.majorsStore.get(this.$route.params.electionId)
            .then(majors => this.majors = majors);

    },
    watch: {
        create_modal() {
            this.modal = this.create_modal;
        },
        modal(){
            if (this.modal !== this.create_modal) this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>
