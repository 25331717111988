<template>
    <div>
        <v-card class="ma-4" elevation="0">
            <v-card-title class="font-weight-bold">Liste</v-card-title>
            <v-card-item>
                <div>
                    <template v-if="!majorsStore.majors">Caricamento...</template>
                    <template v-else>
                        <v-expansion-panels variant="accordion">
                            <major-row v-for="(major, key) in majorsStore.majors" :key="key" :value="major"></major-row>
                        </v-expansion-panels>

                        <v-btn v-if="!creating_new_major" prepend-icon="fa:fas fa-plus" class="my-3 w-100" color="blue" @click="createNewMajor">Aggiungi candidato sindaco</v-btn>
                        <handle-major-values v-else v-model="new_major" :creating="true" @save="addMajor" @close="this.creating_new_major = false"></handle-major-values>
                    </template>
                </div>
            </v-card-item>
        </v-card>
    </div>
</template>

<script>
import {useMajorsStore} from "@/stores/majors";
import MajorRow from "@/components/pages/Lists/MajorRow.vue";
import HandleMajorValues from "@/components/pages/Lists/HandleMajorValues.vue";

export default {
    name: "MajorPage",
    components: {HandleMajorValues, MajorRow},
    setup() {
        const majorsStore = useMajorsStore();

        return {majorsStore};
    },
    data() {
        return {
            majors: null,
            saving: false,
            modal: {
                opened: false,
                model: null,
                loading: false
            },
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
            creating_new_major: false,
            new_major: null
        }
    },
    beforeMount() {
        this.majorsStore.get(this.$route.params.electionId);
    },
    methods: {
        createNewMajor() {
            this.new_major = this.getEmptyMajor();
            this.creating_new_major = true
        },
        openModal(id) {
            this.modal.model = this.majorsStore.majors[id];
            this.modal.creating = false;

            this.modal.opened = true;
        },
        confirm() {
            this.modal.loading = true;
            this.majorsStore.delete(this.modal.model.id)
                .then(() => {
                    this.modal.opened = false;
                    this.modal.model = null;
                    this.modal.loading = false;
                })
        },
        getEmptyMajor() {
            return {
                name: null,
                surname: null,
                image: null,
                color: "#FF0000"
            };
        },
        addMajor(){
          this.majorsStore.add(this.$route.params.electionId, this.new_major)
              .then(() => {
                  this.creating_new_major = false;
                  this.new_major = null;
              })
        }
    }
}

</script>

<style scoped>

</style>
