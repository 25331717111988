<template>
  <v-layout>
      <slot></slot>
  </v-layout>
</template>

<script>
export default {
    name: "AppLayout"
}
</script>

<style scoped>

</style>
