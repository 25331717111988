<template>
  <v-card width="300">
      <v-card-title>{{voting_station.station.name}}, {{voting_station.turn.name}}</v-card-title>
      <v-card-item>
          Stato del turno: {{this.status}}
      </v-card-item>
      <v-card-actions>
        <router-link v-if="voting_station.turn.status === 'live'" :to="{name: 'live', params: { votingStationId: voting_station.id }}">
            <v-btn>Vai allo spoglio</v-btn>
        </router-link>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "VotingStationCard",
    props: ["voting_station"],
    computed: {
        status() {
            let val;
            switch (this.voting_station.turn.status){
                case "passed":
                    val = "Chiuso";
                    break;
                case "live":
                    val = "In corso";
                    break;
                case "programmed":
                    val = "Programmato";
                    break;
            }

            return val;
        }
    }
}
</script>

<style scoped>

</style>
