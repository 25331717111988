<template>
    <div class="d-flex justify-space-between align-center my-2" style="gap: 20px">
        <label style="width: 50px; aspect-ratio: 1" class="border-dotted border-2 d-flex align-center justify-center">
            <input type="file" ref="fileInput" accept="image/*" @change="onFileSelected" style="display: none">
            <v-avatar v-if="imageUrl" :image="imageUrl" style="cursor: pointer"></v-avatar>
        </label>
        <v-text-field label="Nome"
                      variant="outlined"
                      v-model="major_values.name"
                      :disabled="saving"
        ></v-text-field>
        <v-text-field label="Cognome"
                      variant="outlined"
                      class="align-center"
                      v-model="major_values.surname"
                      :disabled="saving"
        ></v-text-field>

        <v-btn color="blue" @click="save">Salva</v-btn>
        <v-btn @click="$emit('close')">Chiudi</v-btn>
    </div>
</template>


<script >
export default {
    name: "HandleMajorValues",
    props: ["modelValue"],
    data() {
        return {
            major_values: this.modelValue,
            imageUrl: this.modelValue.image
        }
    },
    beforeMount() {
      this.major_values.image = null;
    },
    methods: {
        save() {
            this.$emit('update:modelValue', this.major_values);
            this.$emit('save');
        },
        onFileSelected(event) {
            this.major_values.image = event.target.files;
            const file = this.major_values.image[0];
            if (!file.type.startsWith('image/')) {
                alert('Selezionare solo file di immagine.');
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                this.imageUrl = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    }
}
</script>
