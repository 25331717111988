<template>
  <home-layout>
      <v-navigation-drawer permanent class="bg-transparent border-none text-left">
          <v-list nav>
              <v-list-item prepend-icon="fa:fas fa-back" title="Indietro" value="back" @click="$router.push({ name: 'dashboard' })"></v-list-item>
              <v-list-item prepend-icon="fa:fas fa-building" title="Generale" value="city" @click="$router.push({ name: 'election' })"></v-list-item>
              <v-list-item prepend-icon="fa:fas fa-users" title="Liste" value="groups" @click="$router.push({ name: 'lists' })"></v-list-item>
              <v-list-item prepend-icon="fa:fas fa-list-ol" title="Turni" value="turns" @click="$router.push({ name: 'turns' })"></v-list-item>
          </v-list>
      </v-navigation-drawer>
      <div>
          <slot></slot>
      </div>
  </home-layout>
</template>

<script>
import HomeLayout from "@/components/layouts/HomeLayout.vue";

export default {
    name: "ElectionLayout",
    components: {HomeLayout},
}
</script>

<style scoped>

</style>
