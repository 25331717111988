<template>
    <div class="w-100">
        <v-expansion-panel v-if="!edit" >
            <v-expansion-panel-title @click.once="expanded = true">
                <div class="d-flex justify-space-between align-center w-100">
                    <div class="d-flex align-center" style="gap: 10px">
                        <v-avatar :image="value.image"></v-avatar>
                        <p class="ma-0">{{value.name}} {{value.surname}}</p>
                    </div>
                    <div class="d-flex mr-2" style="gap: 10px">
                        <v-btn size="x-small" icon="fa:fas fa-pencil-alt" color="yellow" @click.stop="edit = !edit"></v-btn>
                        <v-btn size="x-small" icon="fa:fas fa-trash" color="red" @click.stop="deleting = true"></v-btn>
                    </div>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <div class="my-3">
                    <template v-if="expanded">
                        <div v-if="loading_groups">
                            Caricamento liste...
                        </div>
                        <div v-else-if="!groups || !groups.length">
                            Non ci sono gruppi collegati al candidato sindaco
                        </div>
                        <div v-else>
                            <v-expansion-panels variant="accordion">
                                <group-row v-for="(group, key) in groups" :value="group" :key="key"></group-row>
                            </v-expansion-panels>
                        </div>
                    </template>
                    <div class="text-center" v-if="!creating">
                        <v-btn color="blue" prepend-icon="fa:fas fa-plus" class="ma-auto mt-4" @click="creating = true">Aggiungi lista</v-btn>
                    </div>
                    <div v-else>
                        <handle-group-values :creating="true" @update:modelValue="createGroup"></handle-group-values>
                    </div>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
        <div v-else>
            <handle-major-values v-model="major_values" @save="update" @close="edit = false"/>
        </div>

        <v-dialog v-model="deleting">
            <v-card>
                <v-card-title>Sei sicuro di voler cancellare il sindaco {{value.name}} {{value.surname}}?</v-card-title>
                <v-card-text>
                    Attenzione: l'operazione non è reversibile.
                    Cancellando il sindaco selezionato, eliminerai anche le liste e i candidati collegati.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="deleting = false">Chiudi</v-btn>
                    <v-btn color="blue" @click="deleteMajor">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import HandleMajorValues from "@/components/pages/Lists/HandleMajorValues.vue";
import GroupRow from "@/components/pages/Lists/GroupRow.vue";
import {useGroupsStore} from "@/stores/groups";
import {useMajorsStore} from "@/stores/majors";
import HandleGroupValues from "@/components/pages/Lists/HandleGroupValues.vue";

export default {
    name: "MajorRow",
    components: {HandleGroupValues, GroupRow, HandleMajorValues},
    props: {
        value: Object,
    },
    setup() {
        const majorsStore = useMajorsStore()
        const groupsStore = useGroupsStore();

        return { majorsStore, groupsStore };
    },
    data() {
        return {
            major_values: {
                id: this.value.id,
                name: this.value.name,
                surname: this.value.surname,
                image: this.value.image,
                color: this.value.color
            },
            edit: false,
            expanded: false,
            loading_groups: false,
            deleting: false,
            creating: false
        }
    },
    methods: {
        update() {
            this.majorsStore.update(this.major_values)
                .then(() => {
                    this.edit = false;
                    this.majorsStore.refresh(this.$route.params.electionId);
                });
        },
        deleteMajor() {
            this.majorsStore.delete(this.value.id);
        },
        createGroup(group_values) {
            this.groupsStore.add(this.value.id, group_values)
                .then(() => this.creating = false);
        }
    },
    watch: {
        expanded() {
            console.log("expanded", this.expanded);
            if (this.expanded && !this.groupsStore.major_groups[this.value.id]){
                this.loading_groups = true;
                this.groupsStore.getOfMajor(this.value.id)
                    .then(() => this.loading_groups = false);
            }
        }
    },
    computed: {
        groups() {
            return this.groupsStore.major_groups[this.value.id];
        }
    }
}
</script>

<style scoped>

</style>
