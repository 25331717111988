<template>
    <div>
        <v-card class="ma-4">
            <v-card-title class="font-weight-bold" v-if="!turns">Caricamento...</v-card-title>
            <template v-else>
                <v-card-title class="font-weight-bold">Turni</v-card-title>
                <v-card-subtitle>Puoi modificare date e impostazioni dei turni di elezione</v-card-subtitle>
                <v-card-item>
                    <div class="d-flex flex-wrap" style="gap: 15px">
                        <v-card v-for="(turn, key) in turns" :key="key" min-width="300" max-width="300" elevation="0"
                                color="blue">
                            <v-card-title class="pa-3">{{ turn.name }}</v-card-title>
                            <v-card-item>
                                Dal: {{ turnsStore.formatDate(turn.started_at) }}<br/>
                                Al: {{ turnsStore.formatDate(turn.finished_at) }}
                            </v-card-item>
                            <v-card-actions>
                                <router-link :to="{name: 'voting_stations', params: { turnId: turn.id }}">
                                    <v-btn>ASSEGNA</v-btn>
                                </router-link>
                                <v-btn @click="startEdit(key)">MODIFICA</v-btn>
                                <v-btn @click="deleteTurn(key)" color="black">ELIMINA</v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-card min-width="300" max-width="300" elevation="0" color="blue-grey-lighten-5"
                                @click="create_modal = true">
                            <v-card-title class="pa-3">Aggiungi nuovo turno</v-card-title>
                        </v-card>

                        <turn-modal :create_modal="create_modal"
                                    v-model="new_turn"
                                    :creating="true"
                                    @close="create_modal = false"
                                    @save="createNewTurn"
                        ></turn-modal>

                        <turn-modal v-if="edit_modal.status"
                                    :create_modal="edit_modal.status"
                                    v-model="edit_modal.model"
                                    @close="edit_modal.status = false"
                                    @save="updateModal"
                        ></turn-modal>
                    </div>
                </v-card-item>
            </template>
        </v-card>
    </div>
</template>

<script>
import {useTurnsStore} from "@/stores/turns";
import {useMajorsStore} from "@/stores/majors";
import TurnModal from "@/components/pages/Turns/TurnModal.vue";

export default {
    components: {TurnModal},
    component: "TurnsPage",
    setup() {
        const turnsStore = useTurnsStore();
        const majorStore = useMajorsStore()

        return {turnsStore, majorStore};
    },
    data() {
        return {
            turns: null,
            create_modal: false,
            new_turn: {
                name: null,
                started_at: null,
                finished_at: null,
                majors_only: false,
                majors: []
            },
            rules: {
                required: value => !!value || 'Campo obbligatorio',
            },
            creating_new_turn: false,
            majors: [],
            edit_modal: {
                status: false,
                model: null
            }
        }
    },
    beforeMount() {
        this.turnsStore.getOfElection(this.$route.params.electionId)
            .then(turns => this.turns = turns);
        this.majorStore.get(this.$route.params.electionId)
            .then(res => this.majors = res);
    },
    methods: {
        createNewTurn() {
            this.creating_new_turn = true
            this.turnsStore.add(this.$route.params.electionId, this.new_turn)
                .then(() => {
                    this.creating_new_turn = false
                    this.create_modal = false;
                    this.turnsStore.getOfElection(this.$route.params.electionId)
                        .then(turns => this.turns = turns);
                });
        },
        startEdit(key) {
            let model = this.turns[key];
            this.edit_modal.model = {
                id: model.id,
                name: model.name,
                started_at: model.started_at ? this.turnsStore.formatDate(model.started_at) : null,
                finished_at: model.finished_at ? this.turnsStore.formatDate(model.finished_at) : null,
                majors_only: !!model.majors_only,
                majors: model.majors.map(major => major.id)
            };

            this.edit_modal.status = true;
        },
        updateModal() {
            this.turnsStore.update(this.edit_modal.model.id, this.edit_modal.model)
                .then(() => {
                    this.edit_modal.status = false;
                    this.edit_modal.model = null;
                    this.turnsStore.refresh(this.$route.params.electionId)
                        .then(turns => this.turns = turns);
                });
        },
        deleteTurn(key){
            this.turnsStore.deleteTurn(this.turns[key].id)
                .then(() => {
                    this.turnsStore.refresh(this.$route.params.electionId)
                        .then(turns => this.turns = turns);
                });
        }
    }
}

</script>

<style scoped>

</style>
