import { defineStore } from 'pinia'
import axios from "axios";

export const useGroupsStore = defineStore('groups', {
    state: () => ({
        major_groups: {},
    }),
    getters: {

    },
    actions: {
        getOfMajor(id){
            return new Promise((resolve) => {
                if (id in this.major_groups)
                    return resolve(this.major_groups[id]);

                axios.get(`majors/${id}/groups`)
                    .then(res => res.data)
                    .then(res => {
                        this.major_groups[id] = res.data;
                        resolve(this.major_groups[id]);
                    })
            })
        },
        add(major_id, group_values){
            const formData = new FormData();
            formData.append("name", group_values.name);
            formData.append("image", group_values.image[0]);
            return axios.post(`majors/${major_id}/groups`, formData)
                .then(res => res.data)
                .then(res => {
                    this.major_groups[res.data.major.id].push(res.data)
                    return res.data
                });
        },
        update(group_id, group_values) {
            const formData = new FormData();
            formData.append("name", group_values.name);
            formData.append("number", group_values.number);
            formData.append("_method", "PUT");
            if (group_values.image !== null) formData.append("image", group_values.image[0]);

            return axios.post(`groups/${group_id}`, formData)
                .then(res => res.data)
                .then(res => {
                    delete this.major_groups[res.data.major.id];
                    this.getOfMajor(res.data.major.id)
                });
        },
        delete(major_id, group_id){
            return axios.delete(`groups/${group_id}`)
                .then(() => this.major_groups[major_id].splice(this.major_groups[major_id].indexOf(group => group.id === group_id), 1));
        }
    }
});
